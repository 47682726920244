import React, { Component, Fragment } from "react";
import _ from "lodash";
import getFrameworkComponents from "../components/grid-helpers/renderers";
import { AgGridReact } from "ag-grid-react";
import twwstatsOverlay from "../components/grid-helpers/overlay";
import queryString from "query-string";
import analyze from "../services/filter-query-analyzer";
import interpret, {
  extractSynonyms,
} from "../services/filter-query-interpreter";
import { isInt } from "../services/common";
import {
  phaseComparator,
  phasesComparator,
  numberComparator,
  sizeComparator,
  weightComparator,
  isInvertedDelta,
  globalSettings,
} from "../services/twwstats-services";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "../styles.css";

// Workaround: See comment in https://github.com/ag-grid/ag-grid-react/issues/81
// From buhichan commented on 23 Nov 2017
AgGridReact.prototype.areEquivalent = (a, b) => a === b;

function isInvertedDeltaOverride(params) {
  if (
    params.context &&
    params.context.queryVariables &&
    params.context.queryVariables.name === "fatigue" &&
    params.colDef.field === "value"
  ) {
    return true;
  }

  return isInvertedDelta(params.colDef.field);
}

class GenericGrid extends Component {
  constructor(props) {
    super(props);

    const parsed = queryString.parse(this.props.location.search);

    this.state = {
      pinned: (parsed.pinned && _.castArray(parsed.pinned)) || [],
      frameworkComponents: getFrameworkComponents(),
      overlay: twwstatsOverlay,
      tww_version_left: this.props.tww_version_left,
      tww_version_right: this.props.tww_version_right,
      columnDefs: this.props.columnDefs,
      synonyms: extractSynonyms(this.props.columnDefs),
      rowName: "",
      colName: "",
      colValue: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = {};
    if (prevState.quickFilterText !== nextProps.quickFilterText) {
      nextState.quickFilterText = nextProps.quickFilterText;
      nextState.filter_ast_root = analyze(nextProps.quickFilterText);
    }

    let pinned = [];
    if (!nextProps.loading) {
      const parsed = queryString.parse(nextProps.location.search);
      pinned = (parsed.pinned && _.castArray(parsed.pinned)) || [];
    }
    if (!_.isEqual(prevState.pinned, pinned)) {
      nextState.pinned = pinned;
    }

    // For now the column defs should remain constant once they have been initialized
    if (
      !prevState.columnDefs ||
      prevState.columnDefs.length !== nextProps.columnDefs.length
    ) {
      nextState.columnDefs = nextProps.columnDefs;
      nextState.synonyms = extractSynonyms(nextProps.columnDefs);
    }

    return nextState;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.location.search !== this.props.location.search) {
      return true;
    }
    if (nextProps.tww_version_left !== this.props.tww_version_left) {
      return true;
    }
    if (nextProps.tww_version_right !== this.props.tww_version_right) {
      return true;
    }
    if (nextProps.data !== this.props.data) {
      return true;
    }
    if (nextProps.quickFilterText !== this.props.quickFilterText) {
      return true;
    }
    if (nextProps.loading) {
      return true;
    }
    if (nextState.columnDefs.length !== this.state.columnDefs.length) {
      return true;
    }

    return false;
  }

  onGridReady(params, component) {
    this.gridApi = params.api;
    component.gridApi = params.api;

    this.columnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();
  }

  onColumnEverythingChanged(params, component) {
    params.api.sizeColumnsToFit();
  }

  onRowDoubleClicked(params, component) {
    let pinned = _.cloneDeep(this.state.pinned);
    if (
      params.rowPinned === "top" ||
      _.some(pinned, (r) => r === params.data[this.props.rowKey])
    ) {
      // Remove from pinned rows
      _.remove(pinned, (r) => r === params.data[this.props.rowKey]);
    } else {
      // Add to pinned rows
      pinned.push(params.data[this.props.rowKey]);
      window.gtag("event", "select_row", {
        event_category: "engagement",
        event_label: params.data[this.props.rowKey],
      });
    }

    let parsed = queryString.parse(this.props.location.search);
    parsed.pinned = pinned;
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(parsed),
    });
  }

  onColumnGroupOpened(params, component) {
    const cg = params.columnGroup;

    // Store state in local storage
    window.localStorage.setItem(cg.groupId, cg.expanded);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.gridApi && this.gridApi.onFilterChanged();
  }

  isExternalFilterPresent(component) {
    return true;
  }

  doesExternalFilterPass(node, component) {
    if (
      !this.props.quickFilterText ||
      node.columnController.allDisplayedColumns.length === 0
    ) {
      return true;
    }

    const result = interpret(
      this.state.filter_ast_root,
      node,
      this.state.synonyms
    );

    return result;
  }

  render() {
    if (globalSettings().show_raw_json === "true") {
      if (this.props.data && this.props.data.length > 0) {
        const jsonData = JSON.stringify(this.props.data);
        return (
          <div>
            <CopyToClipboard text={jsonData}>
              <button style={{ width: 200, height: 40, margin: 8 }}>
                Copy Json Data to Clipboard
              </button>
            </CopyToClipboard>
            <p>{jsonData}</p>
          </div>
        );
      } else {
        return <div>Loading data, please wait...</div>;
      }
    }

    return (
      <Fragment>
        <div
          className="ag-theme-dark"
          style={{ width: "100%", height: "100%", boxSizing: "border-box" }}
        >
          <AgGridReact
            // properties
            reactNext={true}
            frameworkComponents={this.state.frameworkComponents}
            suppressColumnMoveAnimation={true}
            defaultColDef={{
              headerComponent: "DefaultHeaderRenderer",
              valueFormatter: (params) =>
                params.data["delta_" + params.colDef.field] === undefined
                  ? params.value
                  : `${params.value} (${
                      params.data["delta_" + params.colDef.field] > 0 ? "+" : ""
                    }${params.data["delta_" + params.colDef.field]})`,
              cellClassRules: {
                center: (params) => params.colDef.field !== "name",
                "no-value": (params) => !params.value,
                increased: (params) => {
                  const delta = params.data["delta_" + params.colDef.field];
                  let increased = false;
                  if (delta === undefined) {
                    return false;
                  } else if (delta === "from -1") {
                    increased = false;
                  } else if (/from [0-9]+/.test(delta)) {
                    increased = true;
                  } else {
                    increased = delta > 0;
                  }
                  return isInvertedDeltaOverride(params)
                    ? !increased
                    : increased;
                },
                reduced: (params) => {
                  const delta = params.data["delta_" + params.colDef.field];
                  let reduced = false;
                  if (delta === undefined) {
                    return false;
                  } else if (delta === "from -1") {
                    reduced = true;
                  } else if (/from [0-9]+/.test(delta)) {
                    reduced = false;
                  } else {
                    reduced = delta < 0;
                  }
                  return isInvertedDeltaOverride(params) ? !reduced : reduced;
                },
                adjusted: (params) => {
                  const delta = params.data["delta_" + params.colDef.field];
                  return (
                    delta && !isInt(delta) && !/from (-1|[0-9]+)/.test(delta)
                  );
                },
              },
              minWidth: 36,
              maxWidth: 360,
              sortable: true,
              ...this.props.defaultColDef,
            }}
            columnTypes={{
              booleanColumn: {
                getQuickFilterText: (params) => (params.value ? "1" : "0"),
                cellRenderer: "BooleanRenderer",
              },
              integerColumn: {
                cellRenderer: "IntegerRenderer",
                comparator: numberComparator,
                useInfinite: true,
                valueFormatter: (params) =>
                  params.data["delta_" + params.colDef.field] === undefined
                    ? (params.value && Math.round(params.value * 100) / 100) ||
                      0
                    : `${params.value} (${
                        params.data["delta_" + params.colDef.field] > 0
                          ? "+"
                          : ""
                      }${params.data["delta_" + params.colDef.field]})`,
                getQuickFilterText: (params) =>
                  (params.value && Math.round(params.value * 100) / 100) || "0",
              },
              percentColumn: {
                valueFormatter: (params) =>
                  params.data["delta_" + params.colDef.field] === undefined
                    ? `${params.value * 100}%`
                    : `${params.value * 100}% (${
                        params.data["delta_" + params.colDef.field] > 0
                          ? "+"
                          : ""
                      }${params.data["delta_" + params.colDef.field] * 100}%)`,
                minWidth: 42,
                width: 42,
                maxWidth: 42,
              },
              stringColumn: {
                minWidth: 100,
                width: 100,
                maxWidth: 100,
              },
              keyColumn: {
                headerName: "Key",
                field: "key",
                minWidth: 360,
                width: 360,
                maxWidth: 360,
              },
              deltaStatusColumn: {
                field: "delta_status",
                minWidth: 68,
                width: 68,
                maxWidth: 68,
                cellRenderer: "DeltaStatusRenderer",
              },
              phaseColumn: {
                valueFormatter: (params) => params.value && params.value.name,
                comparator: phaseComparator,
                getQuickFilterText: (params) =>
                  params.value && params.value.name,
                headerComponentParams: {
                  icon: "ui/skins/default/modifier_icon_poison.webp",
                },
                cellRenderer: "PhaseRenderer",
              },
              phasesColumn: {
                valueFormatter: (params) =>
                  params.value &&
                  params.value.reduce((acc, val) => `${acc}, ${val.name}`, ""),
                comparator: phasesComparator,
                getQuickFilterText: (params) => {
                  if (!params.value) return "";

                  let val = params.value.reduce((acc, val) => {
                    let phaseProps = [
                      "requested_stance",
                      "unbreakable",
                      "cant_move",
                      "freeze_fatigue",
                      "fatigue_change_ratio",
                      "ability_recharge_change",
                      "duration",
                      "hp_change_frequency",
                      "ticks",
                      "heal_amount",
                      "damage_chance",
                      "damage_amount",
                      "max_damaged_entities",
                      "resurrect",
                      "mana_regen_mod",
                      "mana_max_depletion_mod",
                      "imbue_magical",
                      "imbue_ignition",
                    ];

                    let truthyProps = phaseProps.filter((prop) => val[prop]);
                    return `${acc}, ${val.statEffects
                      .map((v) => v.stat)
                      .join(", ")}, ${truthyProps.join(", ")}`;
                  }, "");

                  return val;
                },
                headerComponentParams: {
                  icon: "ui/skins/default/modifier_icon_poison.webp",
                },
                cellRenderer: "PhasesRenderer",
              },
              projectileBase: {
                headerComponentParams: {
                  icon: "ui/skins/default/icon_stat_ranged_damage.webp",
                },
                cellRenderer: "IntegerRenderer",
                comparator: numberComparator,
              },
              projectileAp: {
                headerComponentParams: {
                  icon: "ui/skins/default/modifier_icon_armour_piercing_ranged.webp",
                },
                cellRenderer: "IntegerRenderer",
                comparator: numberComparator,
              },
              flamingAttack: {
                headerComponentParams: {
                  icon: "ui/skins/default/modifier_icon_flaming.webp",
                },
                cellRenderer: "FlamingAttackIcon",
                getQuickFilterText: (params) => (params.value ? "1" : "0"),
              },
              magicAttack: {
                headerComponentParams: {
                  icon: "ui/skins/default/modifier_icon_magical.webp",
                },
                cellRenderer: "MagicAttackIcon",
                getQuickFilterText: (params) => (params.value ? "1" : "0"),
              },
              UnitCardColumn: {
                cellRenderer: "UnitCardRenderer",
                minWidth: 36,
                width: 36,
                maxWidth: 36,
                valueFormatter: (params) =>
                  params.value && params.value.onscreen_name,
              },
              entitySizeColumn: {
                comparator: sizeComparator,
                minWidth: 82,
                width: 82,
                maxWidth: 82,
              },
              weightColumn: {
                comparator: weightComparator,
                minWidth: 86,
                width: 86,
                maxWidth: 86,
              },
              ...this.props.columnTypes,
            }}
            defaultColGroupDef={{
              headerName: "",
              marryChildren: true,
            }}
            columnDefs={this.state.columnDefs}
            rowData={this.props.data}
            pinnedTopRowData={this.props.data.filter(
              (u) => this.state.pinned.indexOf(u[this.props.rowKey]) !== -1
            )}
            rowClassRules={{
              ror: (params) => params.data.special_category === "renown",
              elector: (params) =>
                params.data.special_category === "elector_counts",
              crafted: (params) => params.data.special_category === "crafted",
              blessed: (params) =>
                params.data.special_category === "blessed_spawning",
              tech: (params) => params.data.special_category === "tech_lab",
              new: (params) => params.data.delta_status === "new",
              removed: (params) => params.data.delta_status === "removed",
              modified: (params) => params.data.delta_status === "modified",
            }}
            rowHeight={this.props.rowHeight || 50}
            icons={{
              menu: '<i class="fa fa-bars"/>',
              columnVisible: '<i class="fa fa-eye"/>',
              columnHidden: '<i class="fa fa-eye-slash"/>',
              columnRemoveFromGroup: '<i class="fa fa-remove"/>',
              filter: '<i class="fa fa-filter"/>',
              sortAscending: '<i class="fa fa-sort-up"/>',
              sortDescending: '<i class="fa fa-sort-down"/>',
              columnGroupOpened: '<i class="fa fa-plus-circle"/>',
              columnGroupClosed: '<i class="fa fa-minus-circle"/>',
            }}
            // quickFilterText={this.props.quickFilterText}
            isExternalFilterPresent={() => this.isExternalFilterPresent(this)}
            doesExternalFilterPass={(node) =>
              this.doesExternalFilterPass(node, this)
            }
            cacheQuickFilter={true}
            // enableFilter={true}
            // floatingFilter={true}
            context={{
              tww_version: this.props.tww_version_right,
              queryVariables: this.props.queryVariables,
            }}
            overlayLoadingTemplate={this.state.overlay}
            overlayNoRowsTemplate={this.state.overlay}
            // events
            onGridReady={(params) => this.onGridReady(params, this)}
            onColumnEverythingChanged={(params) =>
              this.onColumnEverythingChanged(params, this)
            }
            onRowDoubleClicked={(params) =>
              this.onRowDoubleClicked(params, this)
            }
            onCellClicked={this.props.onCellClicked}
            onColumnGroupOpened={(params) =>
              this.onColumnGroupOpened(params, this)
            }
          />
        </div>
      </Fragment>
    );
  }
}

export default GenericGrid;
